<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                    ref="year"
                    v-model="searchOptions.year"
                    :allowDot="false"
                    :allowMinus="false"
                    :displayComma="false"
                    :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 종합 매출현황</div>
              <div v-show="title.year" class="header-caption">[영업년도 : {{ title.year }}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="bodyGrid"
                  v-bind="bodyGridProps"
                  :dataSource="totalMonthlySales"
                  :aggregates="bodyGridAggregates"
                  @headerCellInfo="onBodyGridHeaderCellInfo"
                  @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {
  overflow: hidden;
  border: none;
}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";

import {
  getEndOfMonth, getFormattedDate,
  getTodayNavigationDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeByIdx,
} from "@/utils/commonCodes";

export default {
  name: "TotalMonthlySales",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        year: null,
      },
      title: {
        year: null,
      },
      totalMonthlySales: [],
      reportSearchOptions: {}
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: false,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "month",
            headerText: "월",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "center",
          },
          {
            field: "div",
            headerText: "영업구분",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            headerText: "내장객수",
            columns: [
              {
                field: "visitor01",
                headerText: "회원",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitor02",
                headerText: "이용권",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitor03",
                headerText: "대행사",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitor04",
                headerText: "비회원",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitor05",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "내장팀수",
            columns: [
              {
                field: "visitTeam01",
                headerText: "18홀",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitTeam02",
                headerText: "9홀",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitTeam03",
                headerText: "9홀추가",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "visitTeam04",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
            ]
          },
          {
            headerText: "순매출",
            columns: [
              {
                field: "netSales01",
                headerText: "입장료",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
              {
                field: "netSales02",
                headerText: "카트/대여",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
              {
                field: "netSales03",
                headerText: "식음매출",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
              {
                field: "netSales05",
                headerText: "기타수입",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
              {
                field: "netSales06",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
            ]
          },
          {
            headerText: "제세공과금",
            columns: [
              {
                field: "taxSales01",
                headerText: "부가세",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 90,
                textAlign: "right",
              },
            ]
          },
          {
            field: "totalSales",
            headerText: "총매출(순매출+제세공과금)",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 130,
            textAlign: "right",
          }
        ],
      };
    },
    bodyGridAggregates() {
      return [];
    },
  },
  mounted() {
  },
  methods: {
    async onViewButtonClicked() {
      const fromDate = this.searchOptions.year + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const {
        tgClosePartDivs,
        tgCloseSalesStores,
        tgClosePaymts,
      } = await GolfErpAPI.fetchTotalMonthlySales({
        fromDate: fromDate,
        toDate: toDate,
      });

      console.log('tgClosePartDivs.===>', tgClosePartDivs);
      console.log('tgCloseSalesStores.===>', tgCloseSalesStores);
      console.log('tgClosePaymts.===>', tgClosePaymts);

      const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      const bsnCodes = commonCodesGetCommonCode("BSN_CODE").filter(item => item.comCode !== "CLOSE" && (item.comCode === 'WEEKDAY' || item.comCode === 'WEEKEND'));
      bsnCodes.push({comCode: "TOTAL", comName: "소계"});

      let memberGradeIdx1 = [];
      commonCodesGetCommonCodeByIdx("MEMBER_GRADE", 1).map(item => {
        item.comCodeAttrbList.map(attrb => {
          memberGradeIdx1.push(attrb);
        });
      });
      let memberGradeIdx1AttrbGroupBy = [
        {
          attrb: "MEMBER",
          attrbName: "회원",
          isTotal: false,
        },
        {
          attrb: "CORP",
          attrbName: "이용권",
          isTotal: false,
        },
        {
          attrb: "AGNCY",
          attrbName: "대행사",
          isTotal: false,
        },
        {
          attrb: "NOM",
          attrbName: "비회원",
          isTotal: false,
        },
        {
          attrb: "TOTAL",
          attrbName: "합계",
          isTotal: true,
        }
      ];

      let totalMonthlySales = [];

      months.forEach(month => {
        bsnCodes.forEach((bsnCode, bsnCodeIdx) => {
          let obj = {
            month: Number(month).toString().concat("월"),
            div: bsnCode.comName,
            bsnCode: bsnCode.comCode,
            isTotal: false,
            isFirst: bsnCodeIdx === 0,
            isColSpan: false,
          };

          // 내장객수
          memberGradeIdx1AttrbGroupBy.forEach((attrb, idx) => {
            const idxName = (idx + 1).toString().padStart(2, "0");
            obj["visitor".concat(attrb.isTotal ? "05" : idxName)] =
                tgClosePaymts
                    .filter(item =>
                        getFormattedDate(item.bsnDate, "MM") === month &&
                        (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                        (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0);
          });

          // 내장팀수
          obj.visitTeam01 =
              tgClosePartDivs
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.visit18HTeam)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.visitTeam02 =
              tgClosePartDivs
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.visit9HTeam)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.visitTeam03 =
              tgClosePartDivs
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.visit9HAditTeam)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.visitTeam04 =
              tgClosePartDivs
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.visitTeam)
                  .reduce((acc, cur) => acc + cur, 0);

          // 순매출
          obj.netSales01 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "FEE")
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.netSales02 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "RENT")
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.netSales03 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "FOOD")
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.netSales04 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "SHOP")
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.netSales05 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "ETC")
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.netSales06 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.netAmt + item.notaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);

          // 제세공과금
          obj.taxSales01 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.vatAmt)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.taxSales02 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.taxSpecialTax)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.taxSales03 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.taxVilTax)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.taxSales04 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.taxEduTax)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.taxSales05 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.notaxFund)
                  .reduce((acc, cur) => acc + cur, 0);
          obj.taxSales06 =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.vatAmt + item.feeTaxAmt)
                  .reduce((acc, cur) => acc + cur, 0);

          obj.totalSales =
              tgCloseSalesStores
                  .filter(item =>
                      getFormattedDate(item.bsnDate, "MM") === month &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                  .map(item => item.totSalesAmt)
                  .reduce((acc, cur) => acc + cur, 0);

          obj.isTotal = bsnCode.comCode === "TOTAL";

          totalMonthlySales.push(obj);
        });
      });

      bsnCodes.forEach((bsnCode, bsnCodeIdx) => {
        let obj = {
          month: bsnCode.comCode === "TOTAL" ? "합계" : bsnCode.comName,
          div: bsnCode.comCode === "TOTAL" ? "합계" : bsnCode.comName,
          bsnCode: bsnCode.comCode,
          isTotal: false,
          isFirst: bsnCodeIdx === 0,
          isColSpan: true,
        };

        // 내장객수
        memberGradeIdx1AttrbGroupBy.forEach((attrb, idx) => {
          const idxName = (idx + 1).toString().padStart(2, "0");
          obj["visitor".concat(attrb.isTotal ? "05" : idxName)] =
              tgClosePaymts
                  .filter(item =>
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                      (attrb.isTotal ? true : memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === attrb.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv)))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0);
        });

        // 내장팀수
        obj.visitTeam01 =
            tgClosePartDivs
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.visit18HTeam)
                .reduce((acc, cur) => acc + cur, 0);
        obj.visitTeam02 =
            tgClosePartDivs
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.visit9HTeam)
                .reduce((acc, cur) => acc + cur, 0);
        obj.visitTeam03 =
            tgClosePartDivs
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.visit9HAditTeam)
                .reduce((acc, cur) => acc + cur, 0);
        obj.visitTeam04 =
            tgClosePartDivs
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.visitTeam)
                .reduce((acc, cur) => acc + cur, 0);

        // 순매출
        obj.netSales01 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                    commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "FEE")
                .map(item => item.netAmt + item.notaxAmt)
                .reduce((acc, cur) => acc + cur, 0);
        obj.netSales02 =
          tgCloseSalesStores
            .filter(item =>
              (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
              commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "RENT")
            .map(item => item.netAmt + item.notaxAmt)
            .reduce((acc, cur) => acc + cur, 0);
        obj.netSales03 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                    commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "FOOD")
                .map(item => item.netAmt + item.notaxAmt)
                .reduce((acc, cur) => acc + cur, 0);
        obj.netSales04 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                    commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "SHOP")
                .map(item => item.netAmt + item.notaxAmt)
                .reduce((acc, cur) => acc + cur, 0);
        obj.netSales05 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                    commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === "ETC")
                .map(item => item.netAmt + item.notaxAmt)
                .reduce((acc, cur) => acc + cur, 0);
        obj.netSales06 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.netAmt + item.notaxAmt)
                .reduce((acc, cur) => acc + cur, 0);

        // 제세공과금
        obj.taxSales01 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.vatAmt)
                .reduce((acc, cur) => acc + cur, 0);
        obj.taxSales02 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.taxSpecialTax)
                .reduce((acc, cur) => acc + cur, 0);
        obj.taxSales03 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.taxVilTax)
                .reduce((acc, cur) => acc + cur, 0);
        obj.taxSales04 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.taxEduTax)
                .reduce((acc, cur) => acc + cur, 0);
        obj.taxSales05 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.notaxFund)
                .reduce((acc, cur) => acc + cur, 0);
        obj.taxSales06 =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.vatAmt + item.feeTaxAmt)
                .reduce((acc, cur) => acc + cur, 0);

        obj.totalSales =
            tgCloseSalesStores
                .filter(item =>
                    (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                .map(item => item.totSalesAmt)
                .reduce((acc, cur) => acc + cur, 0);

        obj.isTotal = bsnCode.comCode === "TOTAL";

        totalMonthlySales.push(obj);
      });

      console.log('totalMonthlySales.===>', totalMonthlySales);
      this.totalMonthlySales = totalMonthlySales;
      this.reportSearchOptions = JSON.parse(JSON.stringify(this.searchOptions));
    },
    print() {
      if (this.totalMonthlySales.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            totalMonthlySales: this.totalMonthlySales,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions:
              "▣ 영업년도 : " + this.reportSearchOptions.year + "년",
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          }
        },
        node
      } = args;

      if (field === "totalSales") {
        if (headerText.indexOf("(") >= 0) {
          const innerHTML = "<div style='height: 24px; text-align: center;'>" + headerText.substring(0, headerText.indexOf("(")).concat("</div><div>").concat(headerText.substring(headerText.indexOf("("))) + "</div>";
          node.innerHTML = innerHTML;
        }
      }
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "month") {
        if (data.isFirst && !data.isColSpan) {
          args.rowSpan = 3;
        }
        if (data.isColSpan) {
          args.colSpan = 2;
          cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
        }
      }

      if (field === "div") {
        if (!data.isColSpan) {
          cell.style.color = data.bsnCode === "TOTAL" ? "" : commonCodesGetColorValue("BSN_CODE", data.bsnCode);
        }
      }

      if (data.isTotal) {
        cell.style.backgroundColor = "#ececec";
      }

      if (
          field &&
          (field.startsWith("firstHalf") ||
              field.startsWith("secondHalf") ||
              field.endsWith("total") ||
              field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }
    },
  }
};
</script>
